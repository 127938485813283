<template>
    <!--begin::Page title-->
    <div
        v-if="pageTitleDisplay"
        :class="`page-title d-flex flex-${pageTitleDirection} justify-content-center flex-wrap me-3`">
        <template v-if="pageTitle">
            <!--begin::Title-->
            <h1
                class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                {{ pageTitle }}
            </h1>
            <!--end::Title-->

            <span
                v-if="pageTitleDirection === 'row' && pageTitleBreadcrumbDisplay"
                class="h-20px border-gray-200 border-start mx-3"></span>

            <!--begin::Breadcrumb-->
            <ul
                v-if="breadcrumbs && pageTitleBreadcrumbDisplay"
                class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <NuxtLink
                        to="/"
                        class="text-muted text-hover-primary">
                        {{ $t(`home`) }}
                    </NuxtLink>
                </li>
                <!--end::Item-->
                <template
                    v-for="item in breadcrumbs">
                    <!--begin::Item-->
                    <li class="breadcrumb-item">
                        <span class="bullet bg-gray-500 w-5px h-2px"></span>
                    </li>
                    <!--end::Item-->
                    <!--begin::Item-->
                    <li class="breadcrumb-item text-muted">{{ item }}</li>
                    <!--end::Item-->
                </template>
            </ul>
            <!--end::Breadcrumb-->
        </template>
    </div>
    <div
        v-else
        class="align-items-stretch"></div>
    <!--end::Page title-->
</template>

<script setup lang="ts">
import { computed } from "vue"
import {
    pageTitleBreadcrumbDisplay,
    pageTitleDirection,
    pageTitleDisplay
} from "@/layouts/config/helper"

const route = useRoute()

const pageTitle = computed(() => {
    return route.name
})

const breadcrumbs = computed(() => {
    return route.meta.breadcrumbs
})
</script>
